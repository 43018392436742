<template>
  <button v-if="getLangCode(item, index) !== 'nl'" type="button" class="btn btn-remove-translation" @click="removeTrans(item, index)">
    <Icon width="20" icon="ic:baseline-close"/>
  </button>
</template>

<script>
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Icon
  },
  props: {
    index: null,
    item: null
  }
}
</script>
