<template>
  <div>
    <header v-if="!isLoading" class="page-header">
      <PageTitle v-if="product" :item="product" property="name"/>
      <div class="buttons">
        <button v-if="!isLoading && checkPermissions(['b2b_orders_product.edit'])" class="btn btn-save" @click="saveProduct">
          <span>{{ product && product.id ? 'B2B product wijzigen' : 'B2B product toevoegen' }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="!isLoading" ref="productForm" v-model="product" name="productForm">
          <formulate-input label="Code" name="product_code" type="text" validation="required"></formulate-input>
          <formulate-input label="Minimum bestelhoeveelheid" name="minimal_total_order_amount" type="number" validation="number|required"></formulate-input>
          <formulate-input label="Besteleenheid" name="minimal_order_amount" type="number" validation="number|required"></formulate-input>
          <formulate-input label="Voorraad" name="stock" type="number" validation="number|required"></formulate-input>
          <formulate-input label="Prijs per stuk" name="unit_price" type="number" validation="number|required"></formulate-input>

          <formulate-input #default="{ index }" groupRepeatable-class="translations-group formulate-input-group-repeatable" name="translations" type="group">
            <h3>Vertaling: {{ activeLocalesBack[getLangCode(product, index)] }}</h3>
            <TranslationsRemoveButton :index="index" :item="product"/>

            <formulate-input name="lang_code" type="hidden"></formulate-input>
            <formulate-input label="Naam" name="name" type="text" validation="required"></formulate-input>
            <formulate-input label="Omschrijving" name="description" type="tiptap"></formulate-input>
          </formulate-input>

          <TranslationsAddButtons :item="product"/>

          <template v-if="images.length > 0">
            <div :key="image.id" v-for="image in images" class="image-placeholder">
              <img :src="image.url" alt="Alt img" width="50"/>
              <button class="btn btn-remove-file" type="button" @click="deleteImage(image)">Afbeelding verwijderen</button>
            </div>
          </template>

          <formulate-input v-if="images.length < 3" label="Afbeelding" :uploader="uploadImage" class="image-upload" error-behavior="submit" name="image" type="file" upload-behavior="live" :validation-messages="{ mime: $t('Logo moet van dit type zijn: jpeg, png of gif.')}" validation="mime:image/jpeg,image/png,image/gif,image/webp"></formulate-input>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'
import TranslationsAddButtons from '@/components/admin/TranslationsAddButtons'
import TranslationsRemoveButton from '@/components/admin/TranslationsRemoveButton'

export default {
  name: 'Global',
  components: {
    PageTitle,
    TranslationsAddButtons,
    TranslationsRemoveButton
  },
  data: function () {
    return {
      isLoading: true,
      id: this.$route.params.id,
      product: null,
      datetime: DateTime,
      images: []
    }
  },
  async mounted () {
    this.product = { translations: [{ lang_code: this.activeLocale }] }

    if (this.id) {
      const productRes = await ApiService.fetchProduct(this.id)
      this.product = productRes.data
      await this.fetchProductImages()
    }

    this.isLoading = false
  },
  methods: {
    async fetchProductImages () {
      if (this.product.images && this.product.images.length > 0) {
        const res = await ApiService.getFiles(this.product.images.toString())
        this.images = res.data.files
      } else {
        this.product.images = []
      }
    },
    async deleteImage (image) {
      this.product.images = this.product.images.filter(fileId => image.id !== fileId)
      this.images = this.images.filter(file => image.id !== file.id)
    },
    async uploadImage (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        this.product.images.push(res.data[0])
        this.product.image = null
        await this.fetchProductImages()
      }
    },
    async saveProduct () {
      this.$formulate.submit('productForm')
      if (await this.$refs.productForm.hasValidationErrors()) {
        return true
      }

      for (const translation of this.product.translations) {
        if (!Object.keys(translation).includes('description')) {
          translation.description = ''
        }
      }

      delete this.product.image

      let request = null
      if (this.product.id) {
        request = ApiService.patchProduct(this.product)
      } else {
        request = ApiService.createProduct(this.product)
      }

      const result = await request

      if (result.status === 200) {
        if (this.product.id) {
          notification('Globale succesvol aangepast!')
        } else {
          notification('Globale succesvol toegevoegd!')
        }

        await this.$router.push({ name: 'admin.products' })
      }
    }
  }
}
</script>
