<template>
  <div v-if="item.translations.length < Object.keys(activeLocalesBack).length">
    <h3>Vertaling toevoegen</h3>

    <div class="language-buttons">
      <button v-if="!hasTrans(item,'fr')" @click="addTrans(item,'fr')" type="button" class="btn">{{ activeLocalesBack['fr'] }}</button>
      <button v-if="!hasTrans(item,'en')" @click="addTrans(item,'en')" type="button" class="btn">{{ activeLocalesBack['en'] }}</button>
      <button v-if="!hasTrans(item,'sv')" @click="addTrans(item,'sv')" type="button" class="btn">{{ activeLocalesBack['sv'] }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: null
  }
}
</script>
